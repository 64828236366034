import React from 'react'
import SuccessPage from '../components/common/SuccessPage'
import Layout from "../components/layout"


const ThankYou = () => {
    return (
        <Layout>
            <SuccessPage/>
        </Layout>
    )
}

export default ThankYou
