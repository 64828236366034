import { Link } from "gatsby";
import React from "react";
import Blog from "../views/home/sections/Blog";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import MetaData from "../common/MetaData";

const SuccessPage = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiSuccessPages {
        nodes {
          title
          description
          link
          image {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: NONE, width: 640)
            }
          }
        }
      }
    }
  `);

  const {
    title,
    description,
    link,
    image
  } = data.allStrapiSuccessPages.nodes[0];

  return (
    <>
      <MetaData pageTitle="Thank You" />
      <section className="section pb-2">
        <div className="container pt-10 mt-6">
          <div className="row gap-y align-items-center">
            <div className="col-lg-6 text-md-left p-4 pr-6">
              <div className="section-heading">
                <h2 className="bold  ">{title}</h2>
                <ReactMarkdown
                  source={description}
                  className="text-secondary"
                />
              </div>
              <Link
                to={`${link}`}
                className="btn btn-primary  btn-rounded btn-lg px-4 py-2 text-t"
                title={`home page link`}
              >
                Go Home
              </Link>
            </div>
            <div className="col-lg-6 ml-lg-auto">
              <GatsbyImage
                image={getImage(image)}
                alt={`${title} page image`}
              />
            </div>
          </div>
        </div>
      </section>
      <Blog />
    </>
  );
};

export default SuccessPage;
