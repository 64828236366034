import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import "../../../../styles/blog.css";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";

const Blog = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiBlogs(sort: { order: DESC, fields: published_at }, limit: 3) {
        nodes {
          content
          description
          slug
          title
          published_at(formatString: "MMMM DD, YYYY")
          readTime
          image {
            childImageSharp {
              gatsbyImageData(quality: 10, width: 400, placeholder: NONE)
            }
          }
          blog_author {
            slug
            name
            role
          }
        }
      }
      allStrapiHomeBlogs {
        nodes {
          title
          description
        }
      }
    }
  `);
  const { nodes: posts } = data.allStrapiBlogs;
  const { title, description } = data.allStrapiHomeBlogs.nodes[0];

  return (
    <section className="section">
      <div className="container pt-0 pb-6">
        <div className="content">
          <div className="row py-6">
            <div className="col-lg-12 blogTitle text-center">
              <h3>{title}</h3>
              <ReactMarkdown source={description} />
            </div>

            {posts &&
              posts.map((post, index) => {
                const createdImage = getImage(post.image);
                return (
                  <div className={`col-lg-4 post-${index}`} key={index}>
                    <article className="blog-list-item row tile is-child post-featured">
                      <Link
                        to={"/blogs/" + post.slug}
                        className="stretched-link"
                        title={`${post.title} page link`}
                        noPrefetch
                      ></Link>
                      <div className="col-lg-12 blogroll-image px-0">
                        {post.image ? (
                          <div className="featured-thumbnail">
                            <GatsbyImage
                              image={createdImage}
                              alt={`${post.title} image`}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-12 content-box">
                        <div className="post-author">
                          <strong>
                            {post.blog_author.name} on {post.published_at}.
                          </strong>
                          <span> {post.readTime}</span>
                        </div>
                        <h3>{post.title}</h3>
                        <p>{post.description}</p>
                      </div>
                    </article>
                  </div>
                );
              })}
            <div className="col-md-12 d-flex justify-content-center">
              <a
                className="btn btn-primary btn-rounded btn-lg view-more text-none"
                href="/blogs"
              >
                View More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
